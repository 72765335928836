import * as React from "react"

import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"

const description =
  "Download all the relevant digital transformation tools, templates, guides, and more to take with you. Wherever you are. Wherever you need them."

const DownloadsPage = () => {
  return (
    <Layout>
      <Seo title="Downloads" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Downloads</h4>
          <p>{description}</p>
        </div>
        <Divider />
      </section>
    </Layout>
  )
}

export default DownloadsPage
